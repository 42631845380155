import banner from './resources/images/banner.png';
import footerImage from './resources/images/ad-foot.png';
import ad1 from './resources/images/ad1.png';
import ad2 from './resources/images/ad2.png';
import ad3 from './resources/images/ad3.png';
import ad4 from './resources/images/ad4.png';
import ad5 from './resources/images/ad5.png';
import ad6 from './resources/images/ad6.png';
import './App.css';
import React, { Component } from 'react';

import { services } from './services/services';
import { utils } from './utils/utils';
import moment from 'moment';
import { PeriodType } from './mytype/period';

// type LottoAnnonceType = {
//   up6?: string,
//   up5?: string,
//   up4?: string,
//   up3?: string,
//   up2?: string,
//   down2?: string
// }

type LottoByOneType = {
  number1?: string,
  number2?: string,
  number3?: string,
  number4?: string,
  number5?: string
}

// type AnnounceType = {
//   isMobile: boolean,
//   currentPeriod?: PeriodType | null,
//   previousPeriod?: PeriodType | null,
//   periods?: PeriodType[],
//   numberAnnounce?: LottoByOneType,
//   duration?: number,
//   showDate?: Date | string | moment.Moment,
//   stopAnnounceDate: Date | string | moment.Moment,
//   condition?: number,
//   endpoint?: string,
//   todayDate?: Date | string | moment.Moment,
//   announceStartDate: Date | string | moment.Moment,
//   announceEndDate: Date | string | moment.Moment,
//   oldAnnounce: boolean,
//   newAnnounce: boolean,
//   beforeStartUp: boolean,
//   oldUpAnnounce: boolean,
//   upAnnounce: boolean
// }

const timeAnnounce = moment().format('YYYY-MM-DD 09:20:00');
const announceStartDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const beforeStartUpAnnounceDate = moment(timeAnnounce).format('YYYY-MM-DD HH:mm:ss');
const afterStartUpAnnounceDate = moment(timeAnnounce).add(4.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const startUpAnnounceDate = moment(timeAnnounce).add(5, 'minute').format('YYYY-MM-DD HH:mm:ss');
const endUpAnnouneDate = moment(timeAnnounce).add(9.59, 'minute').format('YYYY-MM-DD HH:mm:ss');
const upAnnounceDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
const announceEndDate = moment(timeAnnounce).add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');

// const getLoading = () => {
//   return 'loading';
// }

const imageLoading = () => {
  return <img
    style={{ marginRight: 2 }}
    src={require('./resources/images/loading.gif')}
    width={50}
    height={50}
    alt='number'
  />;
}

class App extends Component<any, any> {

  _isMounted: boolean = false;
  animationTimer: any = null;
  upTimer: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      isMobile: false,
      periods: null,
      previousPeriod: null,
      currentPeriod: null,
      numberAnnounce: {
        number1: imageLoading(),
        number2: imageLoading(),
        number3: imageLoading(),
        number4: imageLoading(),
        number5: imageLoading()
      },
      todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      oldUpAnnounce: false,
      beforeAnnonce: false,
      beforeStartUp: false,
      upAnnounce: false,
      afterAnnounce: false,
      isShowing: false,
    }
  }

  componentDidMount() {
    this.getAllStatistics();
    this.initData();

    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.animationTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      this.setState({ isMobile: true });
    }
    else {
      this.setState({ isMobile: false });
    }
  }

  initData = () => {
    this.animationTimer = setInterval(() => {
      const { todayDate } = this.state;
      this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
      if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
        this.beforeAnnonce();
      } else if (
        moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
        && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
      ) {
        this.intervalData();
      } else {
        this.afterAnnounce();
      }
    }, 1000);
  }

  beforeAnnonce = async () => {
    const { todayDate, beforeAnnonce } = this.state;
    if (!beforeAnnonce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 09:30:00");
      const period = await services.getPeriods(dateNow, 'both', '21');
      const up = period.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        beforeAnnonce: true,
        isShowing: true
      });
    }

  }

  intervalData = async () => {
    const { todayDate, oldUpAnnounce, upAnnounce, beforeStartUp } = this.state;

    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      if (!oldUpAnnounce) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading()
          },
          oldUpAnnounce: true,
          isShowing: false
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          numberAnnounce: {
            number1: imageLoading(),
            number2: imageLoading(),
            number3: imageLoading(),
            number4: imageLoading(),
            number5: imageLoading(),
            isShowing: false
          },
          beforeStartUp: true
        });
      }
    }

    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (this.upTimer === null) {
        this.upTimer = setInterval(() => {
          this.setState({
            numberAnnounce: {
              number1: Math.floor(Math.random() * 10),
              number2: Math.floor(Math.random() * 10),
              number3: Math.floor(Math.random() * 10),
              number4: Math.floor(Math.random() * 10),
              number5: Math.floor(Math.random() * 10)
            },
            isShowing: false
          })
        }, 100);
      }
    }

    if (moment(todayDate).toDate().getTime() >= moment(upAnnounceDate).toDate().getTime()) {
      clearInterval(this.upTimer);
      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await services.getStatisticToday();
        // await this.getCurrentPeriod();
        const up = statisticToday.data.up6;

        this.setState({
          numberAnnounce: {
            number1: up[0],
            number2: up[1],
            number3: up[2],
            number4: up[3],
            number5: up[4]
          },
          upAnnounce: true,
          isShowing: true
        })
      }
    }
  }

  afterAnnounce = async () => {
    clearInterval(this.upTimer);
    const { afterAnnounce } = this.state;
    if (!afterAnnounce) {
      const statisticToday = await services.getStatisticToday();
      const up = statisticToday.data.up6;
      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        afterAnnounce: true,
        isShowing: true
      })
    }
  }

  getAllStatistics = async () => {
    const response = await services.getStatistics();
    this.setState({
      periods: response.data
    })
  }

  renderPeriods() {
    const { periods } = this.state;

    if (!periods) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    if (periods.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    return periods.map((period: PeriodType, index: number) => {
      return (
        <tr key={index} className="text-center">
          <td>{utils.convertToThaiDate(period?.dateAnnounce.toString())}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up6}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up6?.substring(1, period.up6.length)}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up3}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up2}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.down2}</td>
        </tr>
      );
    });
  }

  renderDateTime = () => {
    const { todayDate } = this.state;
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartUpAnnounceDate).toDate().getTime()) {
      return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().toString());
    }

    return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().add(-1, 'd').toString());
  }

  renderAnnounceShowing = () => {

    const { numberAnnounce, isShowing } = this.state;
    return <tbody>
      <tr>
        <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 5 ໂຕ</td>
        <td style={{ width: '50%', textAlign: 'center' }}>{isShowing ? `${numberAnnounce.number1}${numberAnnounce.number2}${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}` : imageLoading()}</td>
      </tr>
      <tr>
        <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 4 ໂຕ</td>
        <td style={{ width: '50%', textAlign: 'center' }}>{isShowing ? `${numberAnnounce.number2}${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}` : imageLoading()}</td>
      </tr>
      <tr>
        <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 3 ໂຕ</td>
        <td style={{ width: '50%', textAlign: 'center' }}>{isShowing ? `${numberAnnounce.number3}${numberAnnounce.number4}${numberAnnounce.number5}` : imageLoading()}</td>
      </tr>
      <tr>
        <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 2 ໂຕເທິງ</td>
        <td style={{ width: '50%', textAlign: 'center' }}>{isShowing ? `${numberAnnounce.number4}${numberAnnounce.number5}` : imageLoading()}</td>
      </tr>
      <tr>
        <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 2 ໂຕລຸ່ມ</td>
        <td style={{ width: '50%', textAlign: 'center' }}>{isShowing ? `${numberAnnounce.number1}${numberAnnounce.number2}` : imageLoading()}</td>
      </tr>
    </tbody>
  }

  render(): React.ReactNode {

    const { numberAnnounce, isMobile } = this.state;

    return (
      <div className="container p-0">
        <div className="header d-flex justify-content-center">
          <img src={banner} className="img-fluid" height="250px" alt="ຫວຍ ຫວຍລາວ" />
        </div>
        <div className="row pt-1">
          {!isMobile ? <div className="col-md-3 col-sm-12 col-xs-12 ">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={ad1} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad2} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad3} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
          </div> : <></>}
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div style={{ border: '3px solid #044ad6' }} className="p-2">
              <div className="card" style={{ border: 'none' }}>
                <div className="card-body text-center">
                  <div>
                    <h5><b id="period-date-time">{this.renderDateTime()}</b></h5>
                    <h1 style={{ fontSize: '4rem', fontWeight: 700, letterSpacing: 8 }}>

                      <span id="n1">{numberAnnounce?.number1}</span>
                      <span id="n2">{numberAnnounce?.number2}</span>
                      <span id="n3">{numberAnnounce?.number3}</span>
                      <span id="n4">{numberAnnounce?.number4}</span>
                      <span id="n5">{numberAnnounce?.number5}</span>
                    </h1>
                    <p id="odometer"></p>
                    <h5><b>ເວລາອອກເລກ 09:30</b></h5>
                  </div>
                </div>
              </div>
              <table className="table table-bordered" style={{ marginTop: 5, marginBottom: '0px' }}>
                {this.renderAnnounceShowing()}
              </table>
            </div>

            <div className="alert alert-secondary text-center header-gradient" style={{ margin: '10px 0 5px 0' }} role="alert">
              <h5 style={{ margin: 0, color: 'white' }}><b>ຜົນການອອກເລກລາງວັນ</b></h5>
            </div>

            <div style={{ border: '3px solid #044ad6' }} className="p-2">
              <table className="table table-bordered period" style={{ marginBottom: '5px', fontSize: '13px' }}
                id="period-table">
                <thead>
                  <tr className="text-center">
                    <th style={{ verticalAlign: 'middle' }}>ງວດວັນທີ່</th>
                    <th>ເລກ 5 ໂຕ</th>
                    <th>ເລກ 4 ໂຕ</th>
                    <th>ເລກ 3 ໂຕ</th>
                    <th>ເລກ 2 ໂຕເທິງ</th>
                    <th>ເລກ 2 ໂຕລຸ່ມ</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {this.renderPeriods()}
                </tbody>
              </table>
            </div>

          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 ">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={ad4} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad5} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad6} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
          </div>
        </div>
        <div className="card d-flex justify-content-center m-0">
          <div className="card-body p-0">
            <img src={footerImage} width="100%" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
          </div>
        </div>
      </div>
    )
  }
}

export default App;